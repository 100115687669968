<template>
  <div class="green lighten-4 pa-3" dark>
    <p class="mb-0">La solicitud es de un cliente de Bakarta</p>
    <p class="caption">
      Por favor comprueba que la URL sea similar al ejemplo antes de ejecutar
      <br />
      <b>https://m.bakarta.com/5TySfhasosknwdnfdkdm</b>
    </p>
    <div class="text-center">
      <v-text-field class="inputUrl" v-model="url" />
    </div>
    <v-card-actions>
      <FormButtons
        align-buttons="center"
        accept-button-text="Cambiar a Schweppes"
        cancel-button-text="Para revisión"
        cancel-button-class="grey lighten-1"
        @onClickAcceptButton="changeFromBakartaToSchweppes"
        @onClickCancelButton="rejectedActivation"
      />
    </v-card-actions>
  </div>
</template>

<script>
// Constans
import { ACTIVATION_STATUS } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { getPlaceByIdOrUrl, updatePlace } from '@/services/place'
import { updateCompany } from '@/services/company'
import { updateUser } from '@/services/user'
import { updatePanelCompanyActivations } from '@/services/panelCompanyActivations'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'TransferFromBakartaToSchweppes',
  components: { FormButtons },
  mixins: [uiMixin],
  props: {
    companyActivation: {
      default: null,
      type: Object
    },
    urlDefault: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      url: this.urlDefault
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData'])
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        dismissibleTimeOut: -1,
        text: error,
        type: 'error',
        visible: true
      })
    },
    async changeFromBakartaToSchweppes() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          this.processingRequest = true
          try {
            //   Obtenemos el último parámetro de la URL para buscar el place a cambiar
            const parseUrl = this.url.split('/')
            const longParseUrl = parseUrl.length - 1
            const place = await getPlaceByIdOrUrl(parseUrl[longParseUrl])
            // Si no existe place devolvemos un error
            if (!place)
              throw new Error(
                'La URL no es correcta, por favor comprueba que sea similar a la del ejemplo.'
              )

            const companies = Object.keys(place.companies)
            const users = Object.keys(place.users)
            // Actualizamos los brands del place, las empresas y usuarios a "Schweppes"
            await updatePlace({
              id: place.id,
              brand: 'schweppes',
              companyKeysId: this.companyActivation.companyKeysId
            })
            await Promise.all(
              companies.map(async companyId => {
                await updateCompany({
                  id: companyId,
                  brand: 'schweppes'
                })
              })
            )
            await Promise.all(
              users.map(async userId => {
                await updateUser({
                  id: userId,
                  brand: 'schweppes'
                })
              })
            )
            // Cambiamos el estado de la solicitud a "done"
            await updatePanelCompanyActivations({
              id: this.companyActivation.id,
              status: ACTIVATION_STATUS.done.value,
              fromBakarta: true,
              panelUserId: this.userData.id
            })

            // Mostramos la alerta de que todo se ha realizado
            this.modifyAppAlert({
              text: 'La licencia se ha activado correctamente',
              type: 'success',
              visible: true
            })
            // Emitimos que se ha terminado el proceso para volver a cargar otra solicitud
            this.$emit('onFinishChange', true)
          } catch (error) {
            this.handleError(error.message)
          } finally {
            this.processingRequest = false
          }
        },
        actionButtonText: 'Continuar',
        dismissibleTimeOut: -1,
        text:
          'Se va a proceder a cambiar el establecimiento de Bakarta a Schweppes ¿Quieres continuar?',
        type: 'warning',
        visible: true
      })
    },
    rejectedActivation() {
      this.$emit('onRejected', true)
    }
  }
}
</script>

<style scoped>
.inputUrl {
  width: 400px;
  display: inline-block;
}
</style>
