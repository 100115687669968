// Constants
import { PREFIX_COLLECTIONS } from '@/constants'
import GenericDB from '../generic-db'

export default class User extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    brand: process.env.VUE_APP_BRAND || 'bakarta',
    companies: {}, // Relation object (keys)
    places: {}, // Relation object (keys)
    options: {
      finishOnboarding: false
    },
    role: 'company_manager' // 'company_manager', 'staff'
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}users`)
  }

  // Here you can extend UserDB with custom methods
}
