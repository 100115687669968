import firebase from 'firebase/app'
import 'firebase/functions'

/**
 * Nos suscribimos a un paquete
 *
 * @param {Object} data - request data
 * @return {Object} - package data
 */
export async function subscribeToPackage(data = {}) {
  const result = await firebase
    .functions()
    .httpsCallable('onCall-addOns-subscribeToPackage')(data)
  return result.data
}
