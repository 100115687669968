import moment from 'moment'

/**
 * Transforma una fecha al formato elegido
 * @param {string} updateTimestamp
 * @param {string} format
 */
export function dateFilters(updateTimestamp, format = 'DD/MM/YYYY') {
  const date = moment(updateTimestamp)
  return date.format(format)
}

// Esto se deja para que slint no salte
export async function fakeFunction() {
  return true
}
