import Company from '@/firebase/models/company'

const companyDb = new Company()

/**
 * Get the (default) ration "Precio unico"
 */
export function getDefaultRations() {
  const { rations } = companyDb.getDefaultModel()
  return rations
}

/**
 * Create new company
 *
 * @param {object} data - company data
 * @param {string} id - id item
 */
export async function createCompany(data, id = null) {
  const company = await companyDb.create(data, id)
  return company
}

/**
 * Update company in database
 *
 * @param {object} data - company data
 */
export async function updateCompany(data) {
  const company = await companyDb.update(data)
  return company
}

/**
 * Get company by UID
 *
 * @param {string} id - UID company in database
 */
export async function getCompanyById(id) {
  const company = await companyDb.read(id)
  return company
}
