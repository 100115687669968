// Constants
// Constans
import { PREFIX_COLLECTIONS, DEFAULT_SUBSCRIPTION_PLAN } from '@/constants'
import GenericDB from '../generic-db'

export default class Company extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    brand: process.env.VUE_APP_BRAND || 'bakarta',
    companyKeysId: null,
    name: null,
    places: {}, // Relation object (keys)
    subscriptionPlan: DEFAULT_SUBSCRIPTION_PLAN, // 'free' or 'pro'
    users: {} // Relation object (keys),
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}companies`)
  }

  // Here you can extend Company with custom methods
}
