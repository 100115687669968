import firebase from 'firebase/app'
import 'firebase/functions'

import User from '@/firebase/models/user'

const userDb = new User()

/**
 * Create new user in database
 *
 * @param {object} data - company data
 */
export async function createUser(data, id = null) {
  const user = await userDb.create(data, id)
  return user
}

/**
 * Update user in database
 *
 * @param {object} data - user data
 */
export async function updateUser(data) {
  const user = await userDb.update(data)
  return user
}

/**
 * Get user by UID
 *
 * @param {string} id - UID user in database
 */
export async function getUserById(id) {
  const user = await userDb.read(id)
  return user
}

/**
 * Devuelve la data de un usuario mediante su email
 */
export async function getUserByEmail(email) {
  const result = await firebase
    .functions()
    .httpsCallable('onCall-users-getByEmail')({
    email
  })
  return result.data
}
