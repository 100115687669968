<template>
  <tbody>
    <tr v-for="(item, index) in items" :key="item.id || index">
      <td>{{ item.id }}</td>
      <td>{{ item.createTimestamp | dateFilters }}</td>
      <td>{{ item.companyKeysId }}</td>
      <td>{{ item.area }}</td>
      <td>
        {{ item.companyName }}
        <br />
        <span class="caption">{{ item.email }}</span>
      </td>
      <td>{{ item.type | labelActivationsType }}</td>
      <td>{{ item.status | labelActivationsStatus }}</td>
      <td class="d-flex justify-center align-center">
        <!-- Ver detalles de la activación -->
        <v-btn
          fab
          x-small
          :elevation="0"
          @click="handleClickSeeActivationData(item.id)"
        >
          <v-icon dark>mdi-eye</v-icon>
        </v-btn>
        <!-- Modificar menú (carta) -->
        <v-btn
          v-if="canIEditMenu(item)"
          fab
          x-small
          :elevation="0"
          class="ml-1"
          @click="handleClickEditMenu(item.id)"
        >
          <v-icon dark>mdi-file-document-edit-outline</v-icon>
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { ACTIVATION_STATUS, ACTIVATION_TYPES } from '@/constants'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Filters
import { labelActivationsType, labelActivationsStatus } from '@/filters/app'
import { dateFilters } from '@/filters/dateFilters'

export default {
  name: 'CompanyActivationsListItem',
  filters: { labelActivationsType, labelActivationsStatus, dateFilters },
  mixins: [uiMixin],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    /**
     * Handle click on edit button
     */
    handleClickEditMenu(id) {
      if (id) {
        this.routerPushTo({
          path: `/company-activations-form-menu/${id}`
        })
      }
    },
    /**
     * Handle click on edit button
     */
    handleClickSeeActivationData(id) {
      if (id) {
        this.routerPushTo({
          path: `/company-activations-form/${id}`
        })
      }
    },
    /**
     * The item can be edited (menu)
     *
     * @param {object} item - list's item
     * @return {boolean}
     */
    canIEditMenu(item) {
      return (
        item.type === ACTIVATION_TYPES.assistant.value &&
        (item.status === ACTIVATION_STATUS.inProcess.value ||
          item.status === ACTIVATION_STATUS.done.value)
      )
    }
  }
}
</script>
