<template>
  <div class="company-activations-gimmeone">
    <CustomCard>
      <!-- Gimme one more activation -->
      <template v-if="areThereMore && !companyActivation">
        <FormButtons
          :accept-button-v-options="acceptButtonVOptions"
          :accept-button-loading="processingRequest"
          align-buttons="center"
          accept-button-text="Dame otra activación"
          cancel-button-hide
          @onClickAcceptButton="getCompanyActivation"
        />
      </template>
      <!-- Information about the company to activate -->
      <template v-else-if="areThereMore && companyActivation">
        <v-card class="mx-auto text-center" elevation="0">
          <v-card-text>
            <h6 class="text-h6 text--primary">Datos de la compañía</h6>
            <v-row class="text-left justify-center">
              <v-col cols="4">
                <p class="mt-4">
                  <b>Id Solicitud:</b>
                  {{ companyActivation.id }}
                  <br />
                  <b>Código cliente:</b>
                  {{ companyActivation.companyKeysId }}
                  <br />
                  <b>Compañía:</b>
                  {{ companyActivation.companyName }}
                  <br />
                  <b>Email:</b>
                  {{ companyActivation.email }}
                </p>
              </v-col>
              <v-col cols="4">
                <p class="mt-4">
                  <b>Nombre del Comercial:</b>
                  {{ companyActivation.commercialName }}
                  <br />
                  <b>Email del Comercial:</b>
                  {{ companyActivation.commercialEmail }}
                  <br />
                  <b>Observaciones:</b>
                  {{ companyActivation.supportText }}
                </p>
              </v-col>
            </v-row>
            <!-- Url -->
            <div v-if="companyActivation.placeUrl" class="mb-6">
              <b class="mr-1">Url:</b>
              <a :href="companyActivation.placeUrl" target="_blank">
                {{ companyActivation.placeUrl }}
              </a>
              <br />
            </div>
            <!-- Imágenes -->
            <div v-if="areThereImages" class="images">
              <a
                v-for="(image, index) in companyActivation.images"
                :key="index"
                :href="image"
                target="_blank"
              >
                <v-img :src="image" />
              </a>
            </div>
            <!-- Alerta sobre email ocupado -->
            <template v-if="dataBusyAccount">
              <v-alert dense outlined type="error">
                El email ya ha sido usado,
                <a
                  :href="
                    dataBusyAccount.place.id
                      | placeUrlByIdAndBrand(dataBusyAccount.brand)
                  "
                  target="_blank"
                  >este es el establecimiento que lo tiene registrado
                </a>
              </v-alert>
            </template>
          </v-card-text>
          <v-card-actions v-if="!isBakarta">
            <FormButtons
              :accept-button-v-options="acceptButtonVOptions"
              :accept-button-loading="processingRequest"
              align-buttons="center"
              accept-button-text="Comenzar"
              cancel-button-text="Para revisión"
              @onClickAcceptButton="updateCompanyActivation"
              @onClickCancelButton="showRejectForm = !showRejectForm"
            />
          </v-card-actions>
          <div v-else>
            <TransferFromBakartaToSchweppes
              :company-activation="companyActivation"
              :url-default="companyActivation.placeUrl"
              @onFinishChange="resetViewToInitialButton"
              @onRejected="showRejectForm = !showRejectForm"
            />
          </div>
          <v-card-text v-if="showRejectForm" class="text-center mt-4">
            <div class="contenRejectForm">
              <v-textarea
                v-model="rejectionText"
                outlined
                placeholder="Describe el motivo de la revisión"
                class="textareaReject"
              />
              <v-btn class="secondary" @click="rejectCompanyActivation"
                >Enviar a revisión</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </template>
      <!-- There are no more activations -->
      <template v-else-if="!areThereMore">
        <div class="text-center">
          No hay más compañías que activar por el momento
        </div>
      </template>
    </CustomCard>
  </div>
</template>

<script>
// Constans
import {
  DEFAULT_PASSWORD,
  ACTIVATION_STATUS,
  ACTIVATION_TYPES
} from '@/constants'
// Components
import CustomCard from '@/components/ui/CustomCard'
import FormButtons from '@/components/ui/FormButtons'
import TransferFromBakartaToSchweppes from '@/components/elements/companyActivations/TransferFromBakartaToSchweppes'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Filters
import { placeUrlByIdAndBrand } from '@/filters/app'
// Services
import { createUserWithEmailAndPassword } from '@/services/authentication'
import { createCompany, updateCompany } from '@/services/company'
import { createPlace, getUrlPlace, getPlaceById } from '@/services/place'
import { createUser, updateUser, getUserById } from '@/services/user'
import {
  getNextCompanyActivation,
  updatePanelCompanyActivations
} from '@/services/panelCompanyActivations'
import { subscribeToPackage } from '@/services/addOns'
import { updateCompanyKeys } from '@/services/companyKeys'
import { getUserByEmail } from '@/services/user'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { isBakartaDomain } from '@/utils'

export default {
  name: 'CompanyActivationsGimmeOne',
  components: { CustomCard, FormButtons, TransferFromBakartaToSchweppes },
  filters: { placeUrlByIdAndBrand },
  mixins: [uiMixin],
  data() {
    return {
      areThereMore: true,
      dataBusyAccount: null,
      companyActivation: null,
      processingRequest: false,
      showRejectForm: false,
      rejectionText: null
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    acceptButtonVOptions() {
      return {
        color: 'primary'
      }
    },
    /**
     * Are there images in companyActivations
     *
     * @return {boolean}
     */
    areThereImages() {
      return (
        Array.isArray(this.companyActivation.images) &&
        this.companyActivation.images.length
      )
    },
    /**
     * Show options bakarta domain
     *
     * @return {boolean}
     */
    isBakarta() {
      return (
        this.companyActivation &&
        this.companyActivation.placeUrl &&
        isBakartaDomain(this.companyActivation.placeUrl)
      )
    }
  },
  watch: {
    companyActivation: {
      async handler(value) {
        if (value && value.email) {
          try {
            this.processingRequest = true
            // Buscamos información sobre el email de la activación
            this.dataBusyAccount = await this.getDataBusyAccount(value.email)
          } catch {
            // No hacemos nada si falla
          } finally {
            this.processingRequest = false
          }
        }
      },
      deep: true
    }
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        dismissibleTimeOut: -1,
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Reset view to initial button
     */
    resetViewToInitialButton() {
      this.areThereMore = true
      this.companyActivation = null
    },
    /**
     * Get the variable next company to activate. We get
     * data from DB (Firebase)
     */
    async getCompanyActivation() {
      this.processingRequest = true
      try {
        const companyActivation = await getNextCompanyActivation()

        // Set "companyActivation"
        if (companyActivation) {
          this.companyActivation = companyActivation
          // Ponemos estado "preview" para que esta misma activación
          // no se le muestre a otro "activador"
          await updatePanelCompanyActivations({
            id: this.companyActivation.id,
            status: ACTIVATION_STATUS.preview.value,
            panelUserId: this.userData.id
          })
        } else {
          this.areThereMore = false
        }
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Update the "companyActivation"
     */
    async updateCompanyActivation() {
      this.processingRequest = true
      try {
        // Creating the account and everything else to start with the activation
        await this.creatingProcess()
        // Change status and set the panel user
        await updatePanelCompanyActivations({
          id: this.companyActivation.id,
          status: ACTIVATION_STATUS.inProcess.value,
          panelUserId: this.userData.id
        })
        // Update the companyKeys
        await updateCompanyKeys({
          id: this.companyActivation.companyKeysId,
          activationStatus: ACTIVATION_STATUS.inProcess.value
        })

        // Redirect to /company-activations-form-menu/:id
        this.routerPushTo({
          path: `/company-activations-form-menu/${this.companyActivation.id}`
        })
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Reject the "companyActivation"
     */
    async rejectCompanyActivation() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          this.processingRequest = true
          try {
            // Change type and status to rejected and set the panel user
            await updatePanelCompanyActivations({
              id: this.companyActivation.id,
              type: ACTIVATION_TYPES.rejected.value,
              status: null,
              rejectionText: this.rejectionText,
              panelUserId: this.userData.id
            })
            // Update the companyKeys
            await updateCompanyKeys({
              id: this.companyActivation.companyKeysId,
              activationType: ACTIVATION_TYPES.rejected.value,
              activationStatus: null
            })
            // Change view and reset companyActivation variable
            this.areThereMore = true
            this.companyActivation = null
          } catch (error) {
            this.handleError(error.message)
          } finally {
            this.processingRequest = false
          }
        },
        actionButtonText: 'Enviar',
        dismissibleTimeOut: -1,
        text: 'Se va a enviar a la solicitud a revisión ¿Quieres continuar?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Create the user, company, place and first menu associated to them
     */
    async creatingProcess() {
      // Create user (authentication)
      const user = await createUserWithEmailAndPassword(
        this.companyActivation.email,
        DEFAULT_PASSWORD,
        {
          displayName: this.companyActivation.userName
        }
      )
      // Create company
      const companyCreated = await createCompany({
        name: this.companyActivation.companyName,
        users: { [user.user.uid]: true }
      })
      // Create user (db)
      const userCreated = await createUser(
        { companies: { [companyCreated.id]: true } },
        user.user.uid
      )
      // Make unique URL
      const url = await getUrlPlace(this.companyActivation.companyName)
      // Create place
      const placeCreated = await createPlace({
        companyKeysId: this.companyActivation.companyKeysId,
        name: this.companyActivation.companyName,
        companies: { [companyCreated.id]: true },
        email: this.companyActivation.email,
        users: { [user.user.uid]: true },
        url
      })

      // Update companies
      await updateCompany({
        id: companyCreated.id,
        places: { [placeCreated.id]: true }
      })
      // Update users
      await updateUser({
        id: userCreated.id,
        places: { [placeCreated.id]: true }
      })
      // Update the companyKeys
      await updateCompanyKeys({
        id: this.companyActivation.companyKeysId,
        enabled: false
      })

      // Suscribimos establecimiento a paquete "pro"
      const result = await subscribeToPackage({
        packageId: 'pro',
        subscriptionData: {
          amount: 0,
          currency: 'EUR',
          priceId: 'custom',
          period: 36500
        },
        companyId: companyCreated.id,
        placeId: placeCreated.id
      })

      if (result.ok === false) {
        throw new Error(
          'No se ha realizado correctamente la suscripción del paquete'
        )
      }
    },
    /**
     * Obtenemos los datos acerca de la posible
     * email "ocupado"
     *
     * @param {string} email - email del usuario
     */
    async getDataBusyAccount(email) {
      if (!email) return false

      // Inicializamos datos
      let authData = {}
      let userData = {}
      let placeData = {}

      // Comprobamos si el email está siendo usado
      // en el sistema de autenticación
      authData = await getUserByEmail(email)

      // Obtenemos los datos del usuario de la BD
      if (authData && authData.uid) {
        userData = await getUserById(authData.uid)
      }

      // Obtenemos los datos del establecimiento de la BD
      if (userData && Object.keys(userData.places).length > 0) {
        const placesIds = Object.keys(userData.places)
        placeData = await getPlaceById(placesIds[0])
      }

      return {
        auth: authData,
        user: userData,
        place: placeData
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/variables.scss';
.company-activations-gimmeone {
  .images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .v-image {
      width: 150px;
      height: 150px;
      margin: 0 6px 6px 0;
    }
  }
}
.contenRejectForm {
  width: 450px;
  display: inline-block;
}
</style>
