<template>
  <PanelSection title="Compañías a activar" :hide-back-button="false">
    <div class="company-activations">
      <v-container>
        <!-- Activator -->
        <template v-if="isRightRole('activator')">
          <CompanyActivationsGimmeOne />
        </template>
        <!-- Admin -->
        <template v-if="isRightRole('admin')">
          <CustomDataTable
            :headers="dataTable.headers"
            :items="dataTable.items"
            :items-per-page="dataTable.itemsPerPage"
            :loading="dataTable.loading"
            :page="dataTable.page"
            :search-by="dataTable.searchBy"
            @onPrevPage="handlePrevPage"
            @onNextPage="handleNextPage"
            @onSearch="handleSearch"
            @onChangeItemsPerPage="handleChangeItemsPerPage"
          >
            <template slot-scope="{ items }">
              <CompanyActivationsListItem :items="items" />
            </template>
          </CustomDataTable>
        </template>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Constants
import {
  ROLES,
  TRADER_AREAS,
  ACTIVATION_STATUS,
  ACTIVATION_TYPES
} from '@/constants'
// Components
import PanelSection from '@/components/ui/PanelSection'
import CompanyActivationsGimmeOne from '@/components/elements/companyActivations/CompanyActivationsGimmeOne'
import CustomDataTable from '@/components/ui/CustomDataTable'
import CompanyActivationsListItem from '@/components/elements/companyActivations/CompanyActivationsListItem'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { getEveryPanelCompanyActivations } from '@/services/panelCompanyActivations'

export default {
  name: 'CompanyActivationsList',
  components: {
    PanelSection,
    CompanyActivationsGimmeOne,
    CustomDataTable,
    CompanyActivationsListItem
  },
  data() {
    return {
      // Data table
      dataTable: {
        headers: [
          {
            text: 'id',
            align: 'start',
            value: 'id'
          },
          { text: 'Fecha', value: 'createTimestamp' },
          {
            text: 'Código cliente',
            align: 'start',
            value: 'companyKeysId'
          },
          {
            text: 'Área',
            value: 'area'
          },
          { text: 'Compañía', value: 'companyName' },
          { text: 'Tipo', value: 'type' },
          { text: 'Estado', value: 'status' },
          { text: 'Acciones', align: 'center' }
        ],
        items: [],
        itemsPerPage: 20,
        loading: true,
        page: 1,
        searchBy: [
          {
            id: 'companyKeysId',
            label: 'Código cliente',
            type: 'text',
            typeSearch: 'like'
          },
          {
            id: 'area',
            label: 'Área',
            type: 'select',
            typeSearch: 'exact',
            values: TRADER_AREAS
          },
          {
            id: 'type',
            label: 'Tipo',
            type: 'select',
            typeSearch: 'exact',
            values: Object.values(ACTIVATION_TYPES)
          },
          {
            id: 'status',
            label: 'Estado',
            type: 'select',
            typeSearch: 'exact',
            values: Object.values(ACTIVATION_STATUS)
          },
          {
            id: 'email',
            label: 'E-mail',
            type: 'text',
            typeSearch: 'like'
          }
        ]
      },
      // others
      roles: ROLES
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData'])
  },
  async mounted() {
    await this.setDataTableItems()
  },
  methods: {
    /**
     * Handle click on previous page
     *
     * @param {object} paginationOptions - pagination options
     */
    async handlePrevPage(paginationOptions) {
      await this.setDataTableItems(paginationOptions)
      this.dataTable.page = paginationOptions.page || 1
    },
    /**
     * Handle click on next page
     *
     * @param {object} paginationOptions - pagination options
     */
    async handleNextPage(paginationOptions) {
      await this.setDataTableItems(paginationOptions)
      this.dataTable.page = paginationOptions.page || 1
    },
    /**
     * Handle when change number of items per page
     *
     * @param {object} paginationOptions - pagination options
     */
    async handleChangeItemsPerPage(paginationOptions) {
      await this.setDataTableItems(paginationOptions)
      this.dataTable.itemsPerPage = paginationOptions.limit || 20
      this.dataTable.page = 1
    },
    /**
     * Handle make a search
     *
     * @param {object} searchOptions - pagination options
     */
    async handleSearch(searchOptions) {
      const currentSearchOptions = searchOptions.query
        ? {
            order: {
              field: searchOptions.queryField,
              direction: 'asc'
            },
            ...searchOptions
          }
        : null
      await this.setDataTableItems(currentSearchOptions)
      this.dataTable.page = 1
    },
    /**
     * Check if the user role is right
     *
     *  @param {string} role - user role
     */
    isRightRole(role) {
      return this.userData.role === ROLES[role].value
    },
    /**
     * Set items to data table
     *
     * @param {object} paginationOptions - pagination options
     */
    async setDataTableItems(paginationOptions = null) {
      const orderBy = {
        field: 'createTimestamp',
        direction: 'desc'
      }
      const currentPaginationOptions =
        paginationOptions === null
          ? { order: orderBy }
          : { order: orderBy, ...paginationOptions }
      this.dataTable.loading = true
      this.dataTable.items = await getEveryPanelCompanyActivations(
        currentPaginationOptions
      )
      this.dataTable.loading = false
    }
  }
}
</script>
